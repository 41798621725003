<template>
    <div :class="isValid" style="padding: 5px;" @mouseover="hover = true" @mouseleave="hover = null">
        <b-row style="margin-bottom: 0.2rem;">
            <b-col cols="10">{{element.display_name}} {{displayVariableName}}<span style="font-weight: bold; color: red;">{{isRequired}}</span></b-col>
            <b-col cols="2" v-if="hover">
                <Actions :element="element" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-textarea size="sm" :placeholder="element.options.placeholder" :value="element.options.default_value" :rows="element.options.rows" :max-rows="element.options.max_rows" :no-resize="element.options.no_resize" readonly></b-form-textarea>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Actions from './actions/element-settings.vue'
export default {
    data(){
        return{
            hover: null,
        }
    },
    props:{
        element:{}
    },
    components:{
        Actions
    },
    methods:{
        openConfigModal(element){
            this.$eventHub.$emit('editElement', {id: element.id, form_group: element.form_group});
        },
        deleteElement(element){
            this.$eventHub.$emit('deleteElement', {id: element.id, form_group: element.form_group})
        }
    },
    computed:{
        isRequired(){
            if(this.element.required){
                return "*";
            }
            else{
                return "";
            }
        },
        displayVariableName(){
            if(this.element.name == ''){
                return '(Requires Variable Name)';
            }
            else{
                return `(Data Variable: ${this.element.name})`;
            }
        },
        isValid(){
            if(this.element.name == ''){
                return 'element-invalid';
            }
            else{
                return 'element-valid';
            }
        }
    }
}
</script>

<style>

</style>