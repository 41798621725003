<template>
    <b-modal id="formGroupModal" hide-footer>
        <template #modal-title>
            Manage Form Groups
        </template>
        <div>
            <b-row>
                <b-col>
                    <b-alert variant="info" :show="hasGroups == false">Add one or more groups to get started.</b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="9">
                    <b-form-input v-model="newGroupName"></b-form-input>
                </b-col>
                <b-col cols="3">
                    <b-button v-if="newGroupName != ''" v-on:click="addGroup()">Add</b-button>
                </b-col>
            </b-row>
            <hr>
        </div>
        <div v-if="hasMounted" style="display: flex;" >
            <div v-if="formGroups.length > 0" class="table-responsive" style="width:100%;">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <td class="text-center" style="width: 60px;">
                                <span v-if="!editMode" @click="editMode = !editMode" class="text-danger" >Edit</span>
                                <span v-if="editMode" @click="editMode = !editMode" class="text-primary" >Back</span>
                            </td>
                            <th class="d-flex" style="">Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(group, idx) in formGroups" :key="idx">
                            <td class="text-center">
                                <b-icon v-on:click="removeGroup(group.id)" variant="danger" icon="trash" style="cursor: pointer;"></b-icon>
                            </td>
                            <td v-if="editMode == false">
                                <div style="height:27px;">
                                    {{group.name}}
                                &nbsp;({{group.elements.length}} elements)
                                </div>
                            </td>
                            <td v-if="editMode">
                                <b-form-input style="margin: 0px;" size="sm" class="me-2" v-model="group.name"></b-form-input> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="float: right;">
            <b-button class="mt-3" @click="$bvModal.hide('formGroupModal')">Close</b-button>
            &nbsp;
            <b-button class="mt-3" variant="success" v-on:click="saveGroups">Save</b-button>
        </div>
    </b-modal>
</template>

<script>
import _ from 'lodash'
import {builderFormComputed, builderFormMethods} from '@/state/helpers'
export default {
    data:()=>({
        newGroupName: '',
        formGroups:[],
        hasMounted: false,
        editMode: false,
    }),
    mounted(){
        this.formGroups = _.cloneDeep(this.editData);
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
    computed:{
        ...builderFormComputed,

        hasGroups(){
            if(this.formGroups.length > 0){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...builderFormMethods,
        addGroup(){
            if(this.newGroupName != ''){
                var group = {
                    name: this.newGroupName,
                    id: new Date().getTime(),
                    elements:[],
                };
                this.formGroups.push(group);
                this.newGroupName = '';
            }
            else{
                alert('Invalid Group Name');
            }
        },
        removeGroup(id){
            var groupIndex = _.findIndex(this.formGroups, {id:id});
            if(groupIndex != -1){
                this.formGroups.splice(groupIndex, 1);
            }
        },
        saveGroups(){
            this.$eventHub.$emit('updatedFormGroups', this.formGroups)
        }
    }
}
</script>

<style>

</style>