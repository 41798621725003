<template>
    <div v-if="hasMounted">
        <h5 class="d-flex" for="">Element Specific Options
            <b-button v-if="formData.inputs.length > 0" variant="danger" @click="clearInputGroup" size="sm" class="ms-auto">Clear Input Groups</b-button>
        </h5>
        <b-form-group class="modal-fg" v-if="formData.inputs.length == 0">
            <b-row>
                <b-col cols="12">
                    <b-alert style="font-size: 0.8rem;" show><strong>How many inputs would you like to add?</strong></b-alert>
                </b-col>
                <b-col cols="6">
                    <label style="width: 100%;" for="">Input Field Type</label>
                    
                    <b-dropdown class="type-dropdown" variant="primary">
                        <template #button-content>
                            {{getInputName}}<i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </template>
                        <b-dropdown-item v-for="item in availableElements" :key="item.type" @click="input_group_type = item.type">
                            
                            {{item.display_name}}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col cols="6">
                    <label for="">Number Of Inputs</label>
                    <div style="display: flex">
                        <b-form-input class="me-2" type="number" min=0 max=4 v-model="input_group_inputs"></b-form-input>
                        <b-button @click="createInputGroups()" class="ms-auto" variant="success">Create</b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form-group>
        <b-form-group v-else>
            <b-tabs card>
                <b-tab :title="'Input ' + (Number(input.id)+1)" v-for="(input, idx) in formData.inputs" :key="input.id">
                    <b-form-group class="modal-fg">
                        <!-- ------------- -->
                        <b-form-group class="modal-fg">
                            <b-row>
                                <b-col>
                                    <label for="">Label</label>
                                    <div style="display: flex;">
                                        <b-form-input v-model="input.display_name" class="me-1">
                                        
                                        </b-form-input>
                                        <b-button variant="outline-primary" @click="convertLabelToVariable(idx)" title="Convert label to variable name" class="ms-auto">
                                            <i class="fas fa-share"></i>
                                        </b-button>
                                    </div>
                                </b-col>
                                <b-col>
                                    <label for="">Variable Name</label>
                                    <b-form-input :state="validation" v-model="input.name">
                                        
                                    </b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group class="modal-fg">
                            <b-row>
                                <b-col>
                                    <label for="">Required Field</label>
                                    <b-form-checkbox size="lg" v-model="input.required" name="requiredSwitch" switch>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <!-- ------------- -->
                        <b-row>
                            <b-col>
                                <label for="">Default Input Value</label>
                                <b-form-input v-model="input.options.default_value"></b-form-input>
                            </b-col>
                            <b-col>
                                <label style="width: 100%;" for="">Input Field Type</label>
                                
                                <b-dropdown class="type-dropdown" variant="outline-secondary">
                                    <template #button-content>
                                        {{getFieldTypeName(input.options.type)}}<i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                    </template>
                                    <b-dropdown-item v-for="item in textfieldTypes" :key="item.value" @click="input.options.type = item.value">
                                        
                                        {{item.text}}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group class="modal-fg">
                        <b-row>
                            <b-col>
                                <label for="">Placeholder Text</label>
                                <b-form-input v-model="input.options.placeholder"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-tab>
            </b-tabs>
        </b-form-group>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    data:() => ({
        hasMounted: false,
        formData:{},
        textfieldTypes:[
            { value: 'text', text: 'Normal Text'},
            { value: 'number', text: 'Numbers'},
            { value: 'email', text: 'Email Address'},
            { value: 'password', text: 'Password'},
            { value: 'search', text: 'Search Field'},
            { value: 'url', text: 'Website Link'},
            { value: 'tel', text: 'Phone Number'},
            { value: 'date', text: 'Date Field'},
            { value: 'time', text: 'Time'},
            { value: 'range', text: 'Value Range'},
            { value: 'color', text: 'Colour Picker'},
        ],
        dropdownValue:'',
        dropdownText:'',
        input_group_inputs: 0,
        input_group_type: 'textfield',
    }),
    props:{
        form_data:{},
        availableElements:{},
    },
    watch:{
        formData:{
            deep: true,
            handler(newValue){
                if(newValue != {}){
                    this.$emit('updatedFormData', newValue);
                }
            }
        }
    },
    computed:{
        validation(){
            if(this.formData.name == ''){
                return false;
            }
            else{
                return null;
            }
        },
        getInputName(){
            let name = '';
            let index = _.findIndex(this.availableElements, {type: this.input_group_type});
            if(index != -1){
                name = this.availableElements[index].display_name
            }
            return name;
        },
    },
    methods:{
        getFieldTypeName(value){
            let index = _.findIndex(this.textfieldTypes, {value: value});
            let name = '';
            if(index != -1){
                name = this.textfieldTypes[index].text;
            }
            return name;
        },
        createInputGroups(){
            let index = _.findIndex(this.availableElements, {type: this.input_group_type});
            if(index != -1){
                for(let i = 0; i < this.input_group_inputs; i++){
                    let element = _.cloneDeep(this.availableElements[index]);
                    element.name = this.formData.name + '_' + i;
                    element.id = i;
                    this.formData.inputs.push(element);
                }
            }
        },
        convertLabelToVariable(index){
            if(this.formData.inputs[index].display_name != undefined){
                this.formData.inputs[index].name = this.formData.inputs[index].display_name.toLowerCase();
                this.formData.inputs[index].name = this.formData.inputs[index].name.replace(/ /g, "_");
                this.formData.inputs[index].name = this.formData.inputs[index].name.replace(/-/g, "_");
                this.formData.inputs[index].name = this.formData.inputs[index].name.replace(/'/g, "");
                this.formData.inputs[index].name = this.formData.inputs[index].name.replace(/"/g, "");
                this.formData.inputs[index].name = this.formData.inputs[index].name.replace(/,/g, "");
            }
        },
        clearInputGroup(){
            this.formData.inputs = [];
        }
    },
    mounted(){
        this.formData = _.cloneDeep(this.form_data);
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
}
</script>

<style>

</style>