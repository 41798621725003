<template>
    <b-modal no-close-on-backdrop size="lg" id="configModal" ref="configModal" :title="'Configure Element ' + '('+formData.type+')'">
        <b-form v-if="hasMounted">
            <div>
                <h5 for="">Main options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Label</label>
                            <div style="display: flex;">
                                <b-form-input v-model="formData.display_name" class="me-1">
                                
                                </b-form-input>
                                <b-button variant="outline-primary" @click="convertLabelToVariable()" title="Convert label to variable name" class="ms-auto">
                                    <i class="fas fa-share"></i>
                                </b-button>
                            </div>
                        </b-col>
                        <b-col>
                            <label for="">Variable Name</label>
                            <b-form-input :state="validation" v-model="formData.name">
                                
                            </b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label style="width: 100%;" for="">Field Width</label>
                            <!--<b-dropdown size="sm" :text="colToText(formData.options.cols)">
                                <b-dropdown-item v-on:click="formData.options.cols = 12">Full</b-dropdown-item>
                                <b-dropdown-item v-on:click="formData.options.cols = 6">Half</b-dropdown-item>
                            </b-dropdown>-->
                            <div class="btn-group" >
                                <b-button @click="formData.options.cols = 12" size="sm" :variant="formData.options.cols==12 ?'primary' : 'outline-secondary'">Full</b-button>
                                <b-button @click="formData.options.cols = 6" size="sm" :variant="formData.options.cols==6 ?'primary' : 'outline-secondary'">Half</b-button>
                            </div>
                            <!--<b-form-select v-model="formData.options.cols">
                                <b-form-select-option value=12>Full Width</b-form-select-option>
                                <b-form-select-option value=6>Half Width</b-form-select-option>
                            </b-form-select>-->
                        </b-col>
                        <b-col>
                            <label for="">Required Field</label>
                            <b-form-checkbox size="lg" v-model="formData.required" name="requiredSwitch" switch>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>
            <hr class="simple">

            <!-- ### ELEMENT SPRCIFIC OPTIONS ### -->

            <div v-if="formData.type == 'textfield'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Default Input Value</label>
                            <b-form-input v-model="formData.options.default_value"></b-form-input>
                        </b-col>
                        <b-col>
                            <label style="width: 100%;" for="">Input Field Type</label>
                            
                            <b-dropdown class="type-dropdown" variant="outline-secondary">
                                <template #button-content>
                                    {{getFieldTypeName(formData.options.type)}}<i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </template>
                                <b-dropdown-item v-for="item in textfieldTypes" :key="item.value" @click="formData.options.type = item.value">
                                    
                                    {{item.text}}
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Placeholder Text</label>
                            <b-form-input v-model="formData.options.placeholder"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'textarea'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Default Input Value</label>
                            <b-form-input v-model="formData.options.default_value"></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="">Placeholder Text</label>
                            <b-form-input v-model="formData.options.placeholder"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Minimum Rows</label>
                            <b-form-input type="number" v-model="formData.options.rows"></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="">Maximum Rows</label>
                            <b-form-input type="number" v-model="formData.options.max_rows"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Disable Field Resize</label>
                            <b-form-checkbox v-model="formData.options.no_resize" name="resizeToggle">
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'datepicker'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Default Input Date</label>
                            <b-form-datepicker v-model="formData.options.default_value"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <label for="">Placeholder Text</label>
                            <b-form-input v-model="formData.options.placeholder"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Minimum Date</label>
                            <b-form-datepicker v-model="formData.options.min_date"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <label for="">Maximum Date</label>
                            <b-form-datepicker v-model="formData.options.max_date"></b-form-datepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'select'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col cols="3">
                            <label for="">Value</label>
                            <b-form-input v-model="dropdownValue"></b-form-input>
                        </b-col>
                        <b-col cols="7">
                            <label for="">Text</label>
                            <b-form-input v-model="dropdownText"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input style="visibility: hidden;"></b-form-input>
                            <b-icon scale="1.5" icon="plus-square-fill" class="add-icon" v-on:click="addDropdownOption()"></b-icon>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="formData.option_values"></b-table>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'radio'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col cols="3">
                            <label for="">Value</label>
                            <b-form-input v-model="dropdownValue"></b-form-input>
                        </b-col>
                        <b-col cols="7">
                            <label for="">Text</label>
                            <b-form-input v-model="dropdownText"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input style="visibility: hidden;"></b-form-input>
                            <b-icon scale="1.5" icon="plus-square-fill" class="add-icon" v-on:click="addDropdownOption()"></b-icon>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="formData.option_values"></b-table>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'check'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col cols="3">
                            <label for="">Value</label>
                            <b-form-input v-model="dropdownValue"></b-form-input>
                        </b-col>
                        <b-col cols="7">
                            <label for="">Text</label>
                            <b-form-input v-model="dropdownText"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input style="visibility: hidden;"></b-form-input>
                            <b-icon scale="1.5" icon="plus-square-fill" class="add-icon" v-on:click="addDropdownOption()"></b-icon>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="formData.option_values"></b-table>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'timepicker'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Default Time Value</label>
                            <b-form-input v-model="formData.options.default_value"></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="">Placeholder Text</label>
                            <b-form-input v-model="formData.options.placeholder"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'uploader'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Accepted File Types (.docx .pdf .docx)</label>
                            <b-form-input v-model="formData.options.file_type"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'html'">
                <h5 for="">Element Specific Options</h5>
                <b-form-group class="modal-fg">
                    <b-row>
                        <b-col>
                            <label for="">Accepted File Types (.docx .pdf .docx)</label>
                            <ckeditor :editor="editor" v-model="formData.options.default_value"></ckeditor>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>

            <div v-if="formData.type == 'input_group'">
                <InputGroupOptions @updatedFormData="updateFormData" :availableElements="availableElements" :form_data="formData"/>
            </div>

            <div>
                <b-form-group>
                    <b-row>
                        <b-col cols="12" class="">
                            <label for="">Description Hint (Empty = disabled)</label>
                            <b-form-input v-model="formData.options['popup_text']"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>
        </b-form>

        <!-- ### END ELEMENT SPCIFIC OPTIONS ### -->

        <template #modal-footer>
            <div >
                <b-button v-on:click="$bvModal.hide('configModal')">Close</b-button>
                &nbsp;
                <b-button variant="success" v-on:click="save()">Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import _ from 'lodash'
import {builderFormComputed, builderFormMethods} from '@/state/helpers'

import CKEditor from "@ckeditor/ckeditor5-vue2";
import CustomEditor from "../../../plugin/ckeditor-custom/ckeditor.js";

import { default_elements } from '../data.js'

import InputGroupOptions from './edit-modal-components/inputGroupOptions.vue'

export default {
    components:{
        ckeditor: CKEditor.component,

        InputGroupOptions,
    },
    computed:{
        ...builderFormComputed,
        validation(){
            if(this.formData.name == ''){
                return false;
            }
            else{
                return null;
            }
        },
        getInputName(){
            let name = '';
            let index = _.findIndex(this.availableElements, {type: this.input_group_type});
            if(index != -1){
                name = this.availableElements[index].display_name
            }
            return name;
        }
    },
    data(){
        return{
            editor: CustomEditor,
            editorData: '',
            hasMounted: false,
            formData:{},
            groups:[],
            textfieldTypes:[
                { value: 'text', text: 'Normal Text'},
                { value: 'number', text: 'Numbers'},
                { value: 'email', text: 'Email Address'},
                { value: 'password', text: 'Password'},
                { value: 'search', text: 'Search Field'},
                { value: 'url', text: 'Website Link'},
                { value: 'tel', text: 'Phone Number'},
                { value: 'date', text: 'Date Field'},
                { value: 'time', text: 'Time'},
                { value: 'range', text: 'Value Range'},
                { value: 'color', text: 'Colour Picker'},
            ],

            dropdownValue:'',
            dropdownText:'',


            //* input group component
            input_group_inputs: 0,
            input_group_type: 'textfield',
            availableElements : default_elements,
        }
    },
    methods:{
        ...builderFormMethods,
        updateFormData(new_data){
            this.formData = new_data;
        },
        save(){
            console.log(this.formData);
            this.$emit('savedElement', this.formData);
        },

        colToText(value){
            if(value == 12){
                return 'Full'
            }
            else{
                return 'Half'
            }
        },

        addDropdownOption(){
            if(this.dropdownValue != '' || this.dropdownText != ''){
                this.formData.option_values.push({value: this.dropdownValue, text: this.dropdownText});
                this.dropdownValue = '';
                this.dropdownText = '';
            }
        },
        convertLabelToVariable(){
            if(this.formData.display_name != undefined){
                this.formData.name = this.formData.display_name.toLowerCase();
                this.formData.name = this.formData.name.replace(/ /g, "_");
                this.formData.name = this.formData.name.replace(/-/g, "_");
                this.formData.name = this.formData.name.replace(/'/g, "");
                this.formData.name = this.formData.name.replace(/"/g, "");
                this.formData.name = this.formData.name.replace(/,/g, "");
                
            }
        },
        getFieldTypeName(value){
            let index = _.findIndex(this.textfieldTypes, {value: value});
            let name = '';
            if(index != -1){
                name = this.textfieldTypes[index].text;
            }
            return name;
        },
        getIconForType(type){
            switch(type){
                case 'text':
                    return 'input-cursor-text';

                case 'textarea':
                    return 'textarea-t';

                case 'datepicker':
                    return 'calendar';

                case 'select':
                    return 'list-stars';

                case 'radio':
                    return 'ui-radios-grid';

                case 'check':
                    return 'check2-square';

                case 'timepicker':
                    return 'clock';
                case 'uploader':
                    return 'upload';
                case 'html':
                    return 'code-slash';
                default:{
                    alert(type)
                }
            }
        },

        
    },
    mounted(){
        this.groups = _.cloneDeep(this.availableGroups);
        this.formData = _.cloneDeep(this.editData);
        this.hasMounted = true;
    }
}
</script>

<style>
    .form-group{
        margin-bottom: 1rem;
    }

    /*.type-dropdown>ul>*/
</style>