<template>
    <b-modal hide-footer ref="mappingModal" id="mappingModal" :title=title>
        <div v-if="hasMounted">
            <div>
                <b-row class="btnRow">
                    <b-col cols="4">
                        <label>Sheet</label>
                    </b-col>
                    <b-col>
                        <b-dropdown size="sm" variant="light" style="border-radius: 4px; border: 1px solid grey;">
                            <template #button-content>
                                {{returnSheetNameFromId(mapping.client_app_sheet_id)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                            </template>
                            <b-dropdown-item v-on:click="mapping.client_app_sheet_id = sheet.id;" v-for="sheet in spreadsheets" :key="sheet.id">{{sheet.name}}</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
                <b-row class="btnRow">
                    <b-col cols="4">
                        <label>Tab</label>
                    </b-col>
                    <b-col>
                        <b-dropdown variant="light" style="border-radius: 4px; border: 1px solid grey;" size="sm">
                            <template #button-content>
                                {{returnTabName(mapping.tab_name)}} <b-icon size="sm" icon="caret-down-fill"></b-icon>
                            </template>
                            <b-dropdown-item v-for="(tab, idx) in available_tabs" :key="idx" v-on:click="mapping.tab_name = tab" >{{tab}}</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
                <b-row class="btnRow">
                    <b-col cols="4">
                        <label>Source Cell</label>
                    </b-col>
                    <b-col>
                        <b-form-input :formatter="upperCase" ref="currentCellMappingInput" v-model="mapping.sheet_co_ords"></b-form-input>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="w-100 mt-2">
            <b-button @click="$bvModal.hide('mappingModal');" style="float: right;" variant="warning">Cancel</b-button>
            
            <b-button  class="me-2" style="float: right;" variant="success" @click="saveMapping">Save</b-button>
        </div>
        
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import _ from 'lodash'
import { clientMethods, clientComputed, clientViewMethods, clientViewComputed } from '@/state/helpers'
export default {
    props:{
        element:{}
    },
    data:() => ({
        hasMounted:false,
        item:null,
        mapping:{
            client_app_sheet_id: '',
            sheet_co_ords: '',
            tab_name: '',
        },
        available_tabs: [
                'Scorecard', 'OneScore',
                'BaseData','Yes','MCTable','TargetsV','SpendTables',
                'SpendTables(YES)','SpendTables(NonY)','BCDV',
                'SpendV','TMPSV','OneTarV','LevAmtV'
            ],
    }),
    computed:{
        ...clientComputed,
        ...clientViewComputed,
        title(){
            return `Mapping for ${this.element.display_name} (${this.element.name})`
        }

    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,

        returnSheetNameFromId(id){
            if(id == ''){
                return 'Select Sheet';
            }
            else{
                var index = _.findIndex(this.spreadsheets, {id: id});
                if(index != -1){
                    return this.spreadsheets[index].name;
                }
            }
        },
        returnTabName(name){
            if(name != ''){
                return name;
            }
            else{
                return 'Select Tab';
            }
        },

        upperCase(value){
            return _.toUpper(value);
        },



        saveMapping(){
            var params = {
                /*
                client_app_sheet_id: this.editCell.cell.sheetID,
                    render_view_id: this.selected_view_id,
                    tab_name: this.editCell.cell.tabName,
                    sheet_co_ords: this.editCell.cell.cellID,
                    mapped_cell_id: this.editCell.selectedRowID +'|'+this.editCell.selectedColumnID,
                    data_type: this.editCell.cell.dataType,
                    data_source: this.editCell.cell.data_source,
                    source_view_uid: this.editCell.cell.source_view_id,
                */
                client_app_sheet_id: this.mapping.client_app_sheet_id,
                data_source: 'spreadsheet',
                data_type:'',
                mapped_cell_id: this.item.name,
                render_view_id: this.selectedViewData.id,
                sheet_co_ords: this.mapping.sheet_co_ords,
                source_view_uid: "",
                tab_name: this.mapping.tab_name,
            }

            this.saveViewMapping(params)
                .then(()=>{
                    //*Refresh the selected view data with updated mappings
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.setSelectedView(this.selected_view_id);
                        this.$bvModal.hide('mappingModal');
                    })
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'white',
                        icon: 'success',
                        title:'Saved Mapping',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    })
                })
                .catch((e)=>{ 
                    console.log(e);
                    Swal.fire({
                        toast: true,
                        position: 'top',
                        iconColor: 'red',
                        icon: 'error',
                        title:'Error saving mapping.',
                        customClass: {
                            popup: 'error-toast'
                        },
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true
                    })
                })
        }
    },
    mounted(){
        this.item = _.cloneDeep(this.element);
        var index = _.findIndex(this.selectedViewData.mapping,{mapped_cell_id: this.item.name});
        if(index != -1){
            this.mapping.client_app_sheet_id = this.selectedViewData.mapping[index].client_app_spreadsheet_id;
            this.mapping.sheet_co_ords = this.selectedViewData.mapping[index].sheet_coordinates;
            this.mapping.tab_name = this.selectedViewData.mapping[index].tab_name
        }
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
}
</script>

<style>

</style>