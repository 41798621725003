<template>
    <div>
        <div ><!-- class="bg-soft bg-secondary" style="padding: 10px; border-radius: 6px;" -->
            <b-row v-if="!refreshList">
                <b-col cols=9 > <!-- max-height: 50em; -->
                    <div class="column-div">
                        <b-overlay :show="isSaving">
                            <h2 class="pt-1">
                                <b-button @click="$eventHub.$emit('changeSelectedViewType', 'group_table')" style="padding-top: 5px; padding-bottom: 5px;" variant="outline-primary">
                                    <b-icon icon="arrow-left" class="label-icon"></b-icon> Back
                                </b-button>
                                {{selectedViewData.name}}
                                <div style="float: right;">
                                    <!--<span class="btn-group">
                                        <b-button v-show="hasClipboardItem" size="sm" variant="info" v-on:click="useClipboard()"><b-icon icon="clipboard"></b-icon> Test Clipboard</b-button>
                                        <b-button v-show="hasClipboardItem" size="sm" variant="info" v-on:click="useClipboard()"><b-icon icon="clipboard"></b-icon> Test Clipboard</b-button>
                                    </span>&nbsp;-->
                                    <b-button v-show="hasClipboardItem" size="sm" variant="info" v-on:click="setClipboardData(null)"><b-icon icon="clipboard"></b-icon> Clear Clipboard</b-button>&nbsp;
                                    <b-button size="sm" variant="info" v-on:click="openFormGroupModal()"><b-icon icon="pencil"></b-icon> Manage Groups</b-button>&nbsp;
                                    <!--<b-button size="sm" v-on:click="loadExampleData" variant="primary">Load Example</b-button>&nbsp;-->
                                    <b-button size="sm" variant="primary" v-on:click="openGeneratedModal(true)" >Show Form</b-button>&nbsp;
                                    <!--<b-button disabled size="sm" variant="primary" v-on:click="openGeneratedModal(false)"><strong>Show Form With Data</strong></b-button>&nbsp;-->
                                    <b-button size="sm" @click="saveConfig()" variant="success"><i class="fas fa-file-export"></i> Save</b-button>
                                    
                                </div>
                            </h2>
                            <div style="overflow-y: auto; overflow-x: hidden; max-height: 80vh;">
                                <draggable v-bind="dragOptions" id="groups" group="groups" v-model="addedElements">
                                    <div class="group-fence mb-1" v-for="(group, idx) in addedElements" :key="idx" >
                                        <label class="ms-2 bold card-title mb-0" style="width: 100%;" @mouseover="hoverGroup = idx;" @mouseleave="hoverGroup = null;" for="" >
                                            {{group.name}}
                                            &nbsp;
                                            <b-icon v-on:click="openGroupConditionalModal(group.id)" v-if="idx == hoverGroup" title="Manage " icon="pencil" style="cursor: pointer;"></b-icon>
                                        </label>
                                            <draggable :id="idx" @end="onEnd" class="group-area" v-model="group.elements" group="elements">
                                                <div id="added-list" class="added-list" v-for="(item, idy) in group.elements" :key="idy" style="border-radius: 0.25rem;" @mouseover="hoverElement = item.id" @mouseleave="hoverElement = null">
                                                    <component :is="returnComponentType(item)" :element="item"  style="margin: 10px!important; cursor:n-resize;"/>
                                                </div>
                                            </draggable>
                                        <hr>
                                    </div>
                                </draggable>
                                <div v-if="addedElements.length == 0" class="text-center pb-3" style="padding-top: 2rem;">
                                    <h4>Add a form group to get started.</h4>
                                    <b-button @click="openFormGroupModal()" squared size="lg" variant="outline-primary">
                                        <i class="fas fa-plus"></i>
                                    </b-button>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </b-col>
                <b-col cols="3" style="margin-bottom:5px;">
                    <div class="column-div">
                        <h2 class="p-1 pb-0" v-b-toggle.accordion-1>Input Fields</h2>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <draggable id="inputs" @end="onEnd" style="padding:5px; margin:5px; min-height: 200px; background: darkgrey; border-radius: 2px;" v-model="availableElementsIndexed" :group="{ name: 'elements', pull: 'clone', put: false }" sort=false>
                                <div id="default" v-for="item in availableElements" :key="item.type">
                                    <div class="element-list" style="">
                                        <strong>
                                            <b-icon :icon="getIconForType(item.type)" style="margin-right: 5px; margin-left: 5px;"></b-icon>
                                            {{item.display_name}}
                                        </strong>
                                    </div>
                                </div>
                            </draggable>
                        </b-collapse>
                        <hr class="mb-0">
                        <h2 class="p-1 pb-0" v-b-toggle.accordion-2>Preview</h2>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <div style="padding:5px; margin:5px; background: darkgrey; border-radius: 6px; max-height: 55vh; overflow-y: scroll;">
                                <div v-for="(group, idx) in addedElements" :key="idx" >
                                    <div style="padding:5px 25px 5px 25px; margin:5px; background: lightgrey; border-radius: 3px;">
                                        <p class="mb-0" style="text-decoration: underline;">{{group.name}}</p>
                                        <b-row>
                                            <b-col :cols="element.options.cols" v-for="element in group.elements" :key="element.id" style="margin-bottom: 10px;">
                                                <label class="text-truncate" style="display: block; font-size: 0.8em; margin-bottom: 0px;" for="">{{element.display_name}}</label>
                                                <div style="display: flex; align-items: center; justify-content: center; border-radius: 16px; background-color: #4f4f4f; height: 10px;" :style="hasConditionalRendering(element.options.conditional_id)">
                                                    <div v-if="styleHoverElement(element.id)" style="border-radius:8px; width: 90%; height: 2px; background-color: white;"></div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                        <hr class="mb-0">
                        <h2 class="p-1 pb-0" v-b-toggle.accordion-3>Extra Options</h2>
                        <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                            <div>
                                <label>Pagination elements: (0 = disabled)</label>
                                <b-form-input v-model="extra_items.pagination" type="number"/>
                            </div>
                        </b-collapse>
                    </div>
                </b-col>
            </b-row>
        </div>
        <MappingModal v-if="showElementMappingModal" :element="element_mapping_data" />
        <group-conditional-rendering-modal v-if="showGroupConditionalRenderingModal" ></group-conditional-rendering-modal>
        <generated-modal :emptyForm="showEmptyForm" v-if="showGeneratedModal"></generated-modal>
        <element-modal v-if="showModal" @savedElement="updateAddedElements"></element-modal>
        <form-group-modal v-if="showFormGroupModal"></form-group-modal>
        <conditional-rendering-modal
            v-if="showConditionalRenderingModal"
            @savedElement="updateAddedElements"

        ></conditional-rendering-modal>
    </div>
</template>

<script>
import { builderFormMethods, builderFormComputed } from '@/state/helpers'

import ElementModal from './modals/editElementModal.vue'
import GeneratedModal from './modals/generatedFormModal.vue'
import FormGroupModal from './modals/formGroupsModal'
import ConditionalRenderingModal from './modals/conditionalRenderingModal.vue'
import GroupConditionalRenderingModal from './modals/groupConditionalModal.vue'
import MappingModal from './modals/mappingModal.vue'

import TextField from './elements/textfield.vue'
import TextArea from './elements/textarea.vue'
import DatePicker from './elements/datepicker.vue'
import Dropdown from './elements/dropdown.vue'
import Radio from './elements/radio.vue'
import Check from './elements/check.vue'
import TimePicker from './elements/timepicker.vue'
import Uploader from './elements/upload.vue'
import Downloader from './elements/download.vue'
import HtmlText from './elements/htmlText.vue'
import InputGroup from './elements/inputGroup.vue'

import draggable from 'vuedraggable'
import _ from 'lodash'

import Swal from 'sweetalert2'

import { default_elements, example_data } from './data.js'

import { clientMethods, clientComputed, clientViewMethods, clientViewComputed} from '@/state/helpers'

export default {
    components:{
        draggable,
        ElementModal,
        FormGroupModal,
        GeneratedModal,
        ConditionalRenderingModal,
        GroupConditionalRenderingModal,
        MappingModal,


        TextField,
        TextArea,
        DatePicker,
        Dropdown,
        Radio,
        TimePicker,
        Uploader,
        Downloader,
        HtmlText
    },
    data:()=>({
        isSaving: false,
        availableElements : default_elements, //populated from data.js
        addedElements : [],
        extra_items: {
            pagination: 0,
        },

        manage_clipboard:{
            group_id:null,
        },

        showModal : false,
        showFormGroupModal: false,
        showGeneratedModal : false,
        showConditionalRenderingModal: false,
        showGroupConditionalRenderingModal: false,

        showElementMappingModal: false,
        element_mapping_data:null,

        showEmptyForm: true,
        refreshList: false,

        hoverElement:null,
        hoverGroup: null,
    }),
    computed:{
        ...builderFormComputed,
        ...clientViewComputed,
        ...clientComputed,

        availableElementsIndexed:{
            get(){
                var array = _.cloneDeep(this.availableElements);
                array.forEach(element => {
                    if(element == undefined){
                        console.log("err");
                    }
                });
                return array;
            },
            set(){
                //do nothing
            }
        },

        dragOptions() {
            return {
                animation: 700,
                group: "description",
                disabled: false,
                ghostClass: "ghost-element"
            };
        },
        hasClipboardItem(){
            return this.clipboard != null ? true : false ;
        }
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,
        ...builderFormMethods,

        onEnd(event){
            if(event.from.id == 'inputs'){
                var updateElements = _.cloneDeep(this.addedElements)//
                updateElements.forEach(group => {
                    group.elements.forEach(element => {
                        element.form_group = group.id;
                    })
                });
                this.addedElements = updateElements;
                this.$nextTick(()=>{
                    this.generateNewID();
                })
            }
            else if(event.to.id == 'inputs'){
            //* Tried to drag element to input fields list
            }
            else{
                if(event.to.id != event.from.from){
                    //*Element was dragged to a different form group
                    let index = _.findIndex(this.addedElements, {id: Number(event.to.id)})
                    if(index != -1){
                        //*Update the current element's form group id
                        this.addedElements[index].elements[event.newIndex].form_group = event.to.id;
                    }
                }
            }
        },

        editMappingEvent(item){
            var groupId = Number(item.form_group);
            //*Check if group is valid
            var groupIndex = _.findIndex(this.addedElements, {id:groupId})
            if(groupIndex != -1){
                var elementIndex = _.findIndex(this.addedElements[groupIndex].elements, {id:item.id})
                if(elementIndex != -1){
                    this.openMappingModal(this.addedElements[groupIndex].elements[elementIndex]);
                }
            }
        },

        editElementEvent(item){
            var groupId = Number(item.form_group);
            //*Check if group is valid
            var groupIndex = _.findIndex(this.addedElements, {id:groupId})
            if(groupIndex != -1){
                var elementIndex = _.findIndex(this.addedElements[groupIndex].elements, {id:item.id})
                if(elementIndex != -1){
                    this.openConfigModal(this.addedElements[groupIndex].elements[elementIndex]);
                }
            }
        },
        deleteElementEvent(item){
            var groupId = Number(item.form_group);
            //*Check if group is valid
            var groupIndex = _.findIndex(this.addedElements, {id:groupId})
            if(groupIndex != -1){
                var elementIndex = _.findIndex(this.addedElements[groupIndex].elements, {id:item.id})
                if(elementIndex != -1){
                    this.addedElements[groupIndex].elements.splice(elementIndex, 1);
                }
            }
        },

        openFormGroupModal(){
            this.editElement(this.addedElements)
            .then(()=>{
                this.showFormGroupModal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('formGroupModal');
                    this.$root.$once('bv::modal::hidden',(event)=>{
                        if(event.type == 'hidden' && event.componentId == 'formGroupModal'){
                            this.showFormGroupModal = false;
                        }
                    })
                })
            })
        },

        openMappingModal(data){
            this.element_mapping_data = _.cloneDeep(data);
            this.showElementMappingModal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('mappingModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'mappingModal'){
                        this.showElementMappingModal = false;
                    }
                })
            })
        },

        openConfigModal(data){//* Open modal to edit selected form element.
            this.editElement(data)
            .then(()=>{
                var groups = [];
                this.addedElements.forEach(group => {
                    groups.push({id: group.id, name: group.name});
                })
                this.loadAvailableGroups(groups)
                .then(()=>{
                    this.showModal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('configModal');
                        this.$root.$once('bv::modal::hidden',(event)=>{
                            if(event.type == 'hidden' && event.componentId == 'configModal'){
                                this.showModal = false;
                            }
                        })
                    })
                })
            })
        },

        openGeneratedModal(showEmpty){//* Open modal to generate form from added elements
            if(this.addedElements.length > 0){
                var valid = true;
                this.addedElements.forEach(group => {
                    group.elements.forEach(element => {
                        if(element.name == ''){
                            valid = false;
                        }
                    })
                });

                if(valid){
                    this.loadGeneratedFormData(this.addedElements)
                    .then(()=>{
                        this.showEmptyForm = showEmpty;
                        this.showGeneratedModal = true;
                        this.$nextTick(()=>{
                            this.$bvModal.show('generatedFormModal');
                            this.$root.$once('bv::modal::hidden',(event)=>{
                                if(event.type == 'hidden' && event.componentId == 'generatedFormModal'){
                                    this.showGeneratedModal = false;
                                }
                            })
                        })
                    })
                }
                else{
                    Swal.fire('Error!', 'Please make sure that all elements have valid variable names.');
                }
            }
            else{
                Swal.fire('Empty!', 'Please add some elements.');
            }
        },
        openGroupConditionalModal(id){
            /*var index = _.findIndex(this.addedElements, {id:id});
            if(index != -1){
                this.editElement(this.addedElements[index])
                .then(()=>{
                    var control_elements = [];
                    this.addedElements.forEach(group => {
                        group.elements.forEach((element => {
                            if(element.type == 'select'){
                                control_elements.push(element);
                            }
                        }))
                    })
                    this.loadAvailableGroups(groups)
                    .then(()=>{
                        this.showGroupConditionalRenderingModal = true;
                        this.$nextTick(()=>{
                            this.$bvModal.show('groupRenderingModal');
                            this.$root.$once('bv::modal::hidden',(event)=>{
                                if(event.type == 'hidden' && event.componentId == 'groupRenderingModal'){
                                    this.showGroupConditionalRenderingModal = false;
                                }
                            })
                        })
                    })
                })
            }*/
            console.log(id)
        },

        updateAddedElements(data){
            this.$bvModal.hide('configModal');
            this.$bvModal.hide('conditionalRendering');
            var groupIndex = _.findIndex(this.addedElements, {id: data.form_group});
            if(groupIndex != -1){
                var elementIndex = _.findIndex(this.addedElements[groupIndex].elements, {id:data.id});
                if(elementIndex != -1){
                    this.addedElements[groupIndex].elements[elementIndex] = data;
                }
            }
        },
        updatedFormGroupsEvent(data){
            console.log(data);
            this.$bvModal.hide('formGroupModal')
            this.addedElements = data;
            this.refreshList = true;
            this.$nextTick(()=>{
                this.refreshList = false;
            });
        },
        conditionalRenderingEvent(element){
            this.editElement(
                {
                    current_element: element,
                    groups: this.addedElements
                }
            )
            .then(()=>{
                this.showConditionalRenderingModal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('conditionalRendering');
                    this.$root.$once('bv::modal::hidden',(event)=>{
                        if(event.type == 'hidden' && event.componentId == 'conditionalRendering'){
                            this.showConditionalRenderingModal = false;
                        }
                    })
                })
            })
        },

        loadExampleData(){
            this.refreshList = true;
            this.addedElements = [];
            this.addedElements = example_data;
            this.$nextTick(()=>{
                this.refreshList = false;
            })
        },

        styleHoverElement(id){
            if(id == this.hoverElement){
                return 'background-color: red !important;'
            }
            else{
                return false
            }
        },
        hasConditionalRendering(value){
            if(value == undefined || value == ''){
                return ''
            }
            else{
                return 'background-color: rgb(133 107 234) !important;';
            }
        },

        returnComponentType(item){
            if(item.type == 'textfield'){
                return TextField;
            }
            else if(item.type == 'textarea'){
                return TextArea;
            }
            else if(item.type == 'datepicker'){
                return DatePicker;
            }
            else if(item.type == 'select'){
                return Dropdown;
            }
            else if(item.type == 'radio'){
                return Radio;
            }
            else if(item.type == 'check'){
                return Check;
            }
            else if(item.type == 'timepicker'){
                return TimePicker;
            }
            else if(item.type == 'uploader'){
                return Uploader;
            }
            else if(item.type == 'downloader'){
                return Downloader;
            }
            else if(item.type == 'html'){
                return HtmlText;
            }
            else if(item.type == 'input_group'){
                return InputGroup;
            }
        },
        getIconForType(type){
            //* using bootstrap-vue icons -- 
            switch(type){
                case 'textfield':
                    return 'input-cursor-text';
                case 'textarea':
                    return 'textarea-t';
                case 'datepicker':
                    return 'calendar';
                case 'select':
                    return 'list-stars';
                case 'radio':
                    return 'ui-radios-grid';
                case 'check':
                    return 'check2-square';
                case 'timepicker':
                    return 'clock';
                case 'uploader':
                    return 'upload';//cloud-download
                case 'downloader':
                    return 'cloud-download';//
                case 'html':
                    return 'code-slash';
            }
        },
        makeToast(){
            this.$bvToast.toast('Invalid element found', { title: 'Error', autoHideDelay: 2000, solid: true, variant: 'danger', toaster: 'b-toaster-top-center' });
        },
        generateNewID(){
            var newID = Number(new Date().getTime()) + _.random(999);
            this.availableElements.forEach(element => {
                element.id = newID;
            })
        },
        saveConfig(){
            if(this.selected_view_id != null){
                this.isSaving = true;
                var params = {
                    render_view_id: this.selectedViewData.id,
                    config_json: JSON.stringify(this.addedElements),
                    extra_options:this.extra_items,
                }
                this.saveViewConfig(params)
                .then(()=>{
                    Swal.fire({ toast: true, position: 'top', iconColor: 'white', icon: 'success', title:'Saved Form', customClass: { popup: 'colored-toast' }, showConfirmButton: false, timer: 2000, timerProgressBar: true})
                    this.isSaving = false;
                    this.getViewGroups(this.selected_app.id)
                    .then(()=>{
                        this.$eventHub.$emit('displaySelectedView', this.selectedViewData.id)
                        this.setSelectedView(this.selected_view_id);
                    })
                    .catch(()=>{
                        alert('Error saving config')
                    })
                })
                .catch(()=>{
                    alert('Failed');
                    this.isSaving = false;
                })
            }
        },
        selectViewEvent(){
            if(this.selectedViewData.config_json != null){
                this.addedElements = _.cloneDeep(this.selectedViewData.config_json);

                // this was added later so older forms will have this as undefined
                if(this.selectedViewData.extra_options != undefined){
                    this.extra_options = _.cloneDeep(this.selectedViewData.extra_options);
                }
            }
            else{
                this.openFormGroupModal();
            }
            
        },



        //* clipboard stuff
        useClipboard(){
            console.log('Clipboard', this.clipboard);
        },
        handleElementDuplication(count){
            let group_id = this.clipboard.form_group;
            let index = _.findIndex(this.addedElements, {id: group_id});
            if(index != -1){
                for(let i = 0; i < count; i++){
                    let element = _.cloneDeep(this.clipboard);
                    element.id = window.webix.uid();
                    this.addedElements[index].elements.push(element);
                }
            }
        }
    },
    mounted(){
        this.$eventHub.$on('selectedView', this.selectViewEvent);

        this.$eventHub.$on('editElement', this.editElementEvent);
        this.$eventHub.$on('editFormMapping', this.editMappingEvent);
        this.$eventHub.$on('deleteElement', this.deleteElementEvent);
        this.$eventHub.$on('updatedFormGroups', this.updatedFormGroupsEvent);
        this.$eventHub.$on('editConditionalRendering', this.conditionalRenderingEvent);
        this.generateNewID();

        this.$eventHub.$on('insertDuplicateElements', this.handleElementDuplication);
    },
    beforeDestroy(){
        this.$eventHub.$off('selectedView');

        this.$eventHub.$off('editElement');
        this.$eventHub.$off('editFormMapping');
        this.$eventHub.$off('deleteElement');
        this.$eventHub.$off('updatedFormGroups');
        this.$eventHub.$off('editConditionalRendering');
        this.$eventHub.$off('insertDuplicateElements');
    }
}
</script>

<style>
.group-area{
    padding:5px;
    margin-bottom: 10px;
    min-height: 100px; background: rgb(212, 212, 212);
}
.group-fence{
    margin: 0.4rem;
    border: 1px dashed grey;
    border-radius: 0.6rem;
}
.element-list{
    transition: all 0.2s linear;
    background-color: white;
    padding: 5px;
    margin: 5px;
    border: 1px solid black;
    cursor:grabbing;
}
.element-list:hover{
  text-decoration: underline;
  font-weight: bold;
  background-color: rgb(176, 235, 255);
  transition: all 0.3s linear;

}

.element-added{
  padding:5px;
  margin:5px;
  border: 1px solid darkslategrey;
  border-radius: 6px;
  background-color: lavender;
}
.element-added:hover{
  background-color: deepskyblue;
}

.element-invalid{
  background-color:rgb(243, 191, 191);
  border-radius: 6px;
  border:1px solid red;
}
.element-valid{
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #7885d1;
}

.added-list>div>div>div{
    font-size: 1rem;
}
.clipboard-icon:hover{
    color: rgb(255, 145, 0);
    cursor: pointer;
}


.rendering-icon{
    margin-right: 0.25rem;
}
.rendering-icon:hover{
    color: green;
    cursor: pointer;
}
.delete-icon{
    margin-right: 0.25rem;
}
.settings-icon{
    margin-right: 0.25rem;
}
.settings-icon:hover{
    color:dodgerblue;
    cursor: pointer;
}
.delete-icon:hover{
    color:red;
    cursor: pointer;
}
.add-icon{
    cursor:pointer;
}
.add-icon:hover{
    color:seagreen;
}

.groups-ghost{
    /*background: rgb(255, 134, 158);*/
}

.sortable-ghost{
  /*margin-left: -2%;
  margin-right: -2%;*/
}

.ghost-element{
    margin-left: 2%;
    margin-right: 2%;
    background-color: #4BB543;
}
</style>