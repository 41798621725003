<template>
    <div class="text-center" style="height: 24px; border-radius: 8px;background: #fafafa; cursor:default;">
        <span>
            <b-dropdown menu-class="dropdown-menu-end" variant="whitee" @click="openMappingModal(element)" style="padding: 0px;"  size="sm" text="Duplicate">
                <template #button-content size="sm" class="btn-sm">
                    <b-icon font-scale="1.2" icon="clipboard" class="clipboard-icon"></b-icon>
                </template>
                <div class="p-1">
                    <b-form-input v-model="insert_count"></b-form-input>
                    <b-button class="mt-1 w-100" size="sm" @click="insertDuplucates()">Insert Duplicates</b-button>
                </div>
            </b-dropdown>
        </span>
        <b-icon font-scale="1.2" icon="gear" class="settings-icon" v-on:click="openConfigModal(element)"></b-icon>
        <b-icon font-scale="1.2" icon="trash" class="delete-icon" v-on:click="deleteElement(element)"></b-icon>
        
        <b-dropdown variant="whitee" class="m-0 p-0" size="sm" menu-class="dropdown-menu-end tester">
            <template #button-content size="sm">
                <i class="fas fa-ellipsis-h"></i>
            </template>
            <b-dropdown-item>
                <b-button variant="light" @click="openMappingModal(element)" style="padding: 0px;" size="sm">Mapping</b-button>
            </b-dropdown-item>
            <b-dropdown-item>
                <b-button variant="light" @click="openConditionalRendering(element)" style="padding: 0px;" size="sm">
                    <b-icon font-scale="1.2" icon="eye" class="rendering-icon me-1" ></b-icon>
                    Conditional Rendering
                </b-button>
            </b-dropdown-item>
        </b-dropdown>
        <!--<b-icon font-scale="1.2" icon="clipboard" class="clipboard-icon" v-on:click="copyElementToClipboard()"></b-icon>-->
    </div>
</template>

<script>
import { builderFormMethods } from '@/state/helpers'
export default {
    props:{
        element:{}
    },
    data:()=>({
        insert_count: 1,
    }),
    methods:{
        ...builderFormMethods,
        openConfigModal(element){
            this.$eventHub.$emit('editElement', {id: element.id, form_group: element.form_group});
        },
        deleteElement(element){
            this.$eventHub.$emit('deleteElement', {id: element.id, form_group: element.form_group})
        },
        openConditionalRendering(element){
            this.$eventHub.$emit('editConditionalRendering', element);
        },
        openMappingModal(element){
            this.$eventHub.$emit('editFormMapping', element);
        },
        copyElementToClipboard(){
            this.setClipboardData(this.element);
        },
        insertDuplucates(){
            this.copyElementToClipboard();
            this.$eventHub.$emit('insertDuplicateElements', this.insert_count);
        }
    },
}
</script>

<style>

</style>