<template>
    <b-modal id="groupRenderingModal" hide-footer>
        <template #modal-title>
            Manage Form Groups
        </template>
        
        <div style="float: right;">
            <b-button class="mt-3" @click="$bvModal.hide('groupRenderingModal')">Close</b-button>
            &nbsp;
            <b-button class="mt-3" >Save</b-button>
        </div>
    </b-modal>
</template>

<script>
import _ from 'lodash'
import { builderFormComputed } from '@/state/helpers'
export default {
    data:()=>({
        hasMounted: false,
        current_group:{},
        available_control_elements:[]
    }),
    computed:{
        ...builderFormComputed,
    },
    mounted(){
        this.current_group = _.cloneDeep(this.editData);
        
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
    methods:{
        
    }
}
</script>

<style>

</style>