<template>
    <b-modal size="lg" id="generatedFormModal" ref="generatedFormModal" title="Generated Form">
        <b-container fluid v-if="hasMounted">
            <b-form @submit.prevent v-if="show_plugin_form == false">
                <b-form-group v-for="group in elementsComputed" :key="group.id">
                    <div v-if="group.elements.length > 0">
                        <h4>{{group.name}}</h4>
                        <b-form-row>
                            <b-col class="input-col" :cols="item.options.cols" v-for="item in group.elements" :key="item.id">
                                <label class="input-label" for="">{{item.display_name}}</label>
                                <div v-if="item.type=='textfield'">
                                    <component :is="returnComponentType(item)" :type="item.options.type" :placeholder="item.options.placeholder" :name="item.name" :value="item.options.value" v-model="formData[item.name]"/>
                                </div>
                                <div v-else-if="item.type=='textarea'">
                                    <component :is="returnComponentType(item)" :name="item.name" :placeholder="item.options.placeholder" :rows="item.options.rows" :max-rows="item.options.max_rows" :no-resize="item.options.no_resize" v-model="formData[item.name]" />
                                </div>
                                <div v-else-if="item.type=='select'">
                                    <component :is="returnComponentType(item)" :options="item.option_values" v-model="formData[item.name]" >
                                        <template #first>
                                            <b-form-select-option :value="undefined">-- Please select an option --</b-form-select-option>
                                        </template>
                                    </component>
                                </div>
                                <div v-else-if="item.type=='datepicker'">
                                    <component :is="returnComponentType(item)" :name="item.name" :min="item.options.min_date" :max="item.options.max_date" :placeholder="item.options.placeholder" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" v-model="formData[item.name]" />
                                </div>
                                <div v-else-if="item.type=='radio'">
                                    <component :is="returnComponentType(item)" :options="item.option_values" v-model="formData[item.name]" />
                                </div>
                                <div v-else-if="item.type=='timepicker'">
                                    <component :is="returnComponentType(item)" :placeholder="item.options.placeholder" :value="item.options.default_value" v-model="formData[item.name]" />
                                </div>
                                <div v-else-if="item.type=='uploader'">
                                    <div class="p-1" style="border: 1px solid lightgrey;">
                                        <b-form-file :accept="item.options.file_type" v-model="formData[item.name]" />
                                        <!--<div class="mt-3">Selected file: {{ formData[item.name] ? formData[item.name].name : '' }}</div>-->
                                    </div>
                                </div>
                                <div v-else-if="item.type=='html'">
                                    <div class="p-1" style="border: 1px solid lightgrey;">
                                        <div v-html="item.options.default_value"></div>
                                        <!--<div class="mt-3">Selected file: {{ formData[item.name] ? formData[item.name].name : '' }}</div>-->
                                    </div>
                                </div>
                                <div v-else-if="item.type=='input_group'">
                                    <b-row>
                                        <b-col :cols="calcCol(item.inputs.length)" v-for="input in item.inputs" :key="input.id">
                                            <label class="input-label" for="">{{input.display_name}}</label>
                                            <component :is="returnComponentType(input)" :type="input.options.type" :placeholder="input.options.placeholder" :name="input.name" :value="input.options.value" v-model="formData[item.name]" /> <!-- v-model="formData[item.name]"-->
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-form-row>
                    </div>
                </b-form-group>
            </b-form>
            <div v-else>
                <b-alert :show="formUID == null">Form UID missing</b-alert>
                <form-plugin v-if="formUID" :uid="formUID"/>
            </div>
        </b-container>
        <template #modal-footer>
            <div>
                <b-button v-on:click="toggleView" variant="outline-info">Toggle View</b-button>
                &nbsp;
                <b-button v-on:click="$bvModal.hide('generatedFormModal')">Close</b-button>
                &nbsp;
                <b-button variant="success" v-on:click="save()" type="button">Save</b-button>
                &nbsp;
            </div>
        </template>
    </b-modal>
    
</template>

<script>
import _ from 'lodash'
import {builderFormComputed, builderFormMethods, clientViewComputed} from '@/state/helpers'
//eslint-disable-next-line
import { dummy_data } from '../data.js'
//eslint-disable-next-line
import {initSpreadifyBackend,getSpreadifyBackend} from '@/helpers/spreadify.js';

export default {
    name:"generated-form",
    props:{
        emptyForm:{}
    },
    components:{
        /*tester:{
            component: "test-me",
            template: `<b-form-input :placeholder="placeholder" ></b-form-input>`,
            props:{
                placeholder:{},
            }
        }*/
    },
    data(){
        return{
            hasMounted: false,
            showForm: false,
            formData:{},
            elements:[],
            show_plugin_form: false,
        }
    },
    computed:{
        ...clientViewComputed,
        ...builderFormComputed,
        formUID(){
            if(this.selectedViewData == null){
                return null;
            }
            else{
                return this.selectedViewData.uid;
            }
        },
        elementsComputed(){
            //eslint-disable-next-line
            var shown_elements = [];
            if(this.hasMounted){
                shown_elements = _.cloneDeep(this.elements);
                shown_elements.forEach(group => {
                    console.log(group.name)
                    group.elements.forEach((element, index)=>{
                        if(element.options.conditional_value == false){
                            console.log('---- no condition');
                        }
                        else{
                            console.log(element);
                            if(element.options.conditional_value != this.formData[element.options.conditional_text]){
                                group.elements.splice(index, 1);
                                //alert('Removed (conditional value !=) -> ' + element.display_name + ' -> ' + group.elements.length)
                            }
                            if(this.formData[element.options.conditional_text] == undefined){
                                group.elements.splice(index, 1);
                                //alert('Removed (conditional value undefined) -> ' + element.display_name + ' -> ' + group.elements.length)
                            }
                        }
                    })
                })
            }
            return shown_elements;
            
        }
    },
    methods:{
        ...builderFormMethods,

        findConditionalElementId(id){
            var found_index = -1;
            var group_index = -1;
            this.elements.forEach((group, idx) =>{
                var index = _.findIndex(group.elements, {id:id});
                if(index != -1){
                    found_index = index;
                    group_index = idx;
                }

            })
            console.log({element_index: found_index, group_index: group_index })
            return {element_index: found_index, group_index: group_index };
            
        },
        calcCol(length){
            let col = 12/Number(length);
            return col;
        },

        save(){
            console.log(JSON.stringify(this.formData));
        },
        returnComponentType(item){
            if(item.type == 'textfield'){
                return 'b-form-input'
            }
            else if(item.type == 'select'){
                return 'b-form-select'
            }
            else if(item.type == 'textarea'){
                return 'b-form-textarea'
            }
            else if(item.type == 'datepicker'){
                return 'b-form-datepicker'
            }
            else if(item.type == 'radio'){
                return 'b-form-radio-group'
            }
            else if(item.type == 'timepicker'){
                return 'b-form-timepicker'
            }
            else if(item.type == 'uploader'){
                return 'b-form-file'
            }
            else{
                alert('Add Support: ' + item.type);
                return ''
            }
        },
        toggleView(){
            if(this.show_plugin_form == false){
                this.show_plugin_form = true;
            }
            else{
                this.show_plugin_form = false;
            }
        }
    },
    mounted(){
        /*initSpreadifyBackend('9|Xco8yu4GmOyehJbvnlhAQdu54DBYXmyS180yBAv8');
        setTimeout(()=>{
            getSpreadifyBackend().getViewConfig('624d4cd535935')
            .then(response => {
                console.log('Spreadify: ', response)
            })
        }, 1000);*/
        this.elements = _.cloneDeep(this.generatedFormData);
        if(this.emptyForm == false){
            /*this.elements.forEach(group => {
                group.forEach(element => {
                    this.formData
                })
            })*/
            //eslint-disable-next-line no-unused-vars
            for(const [key, value] of Object.entries(this.elements)){
                this.formData[value.name] = value.name;
            }
            /*this.elements.forEach(element => {
                this.formData[element.name] = element.name;
            })*/
            this.formData = dummy_data;
        }
        //console.log(this.formData);
        this.hasMounted = true;
        
    }
}
</script>

<style>
.input-col{
    margin-bottom: 15px;
}
.input-label{
    margin-bottom:2px;
}
</style>