<template>
    <b-modal id="conditionalRendering" title="Configure Conditional Rendering">
        <b-form>
            <h6>Select Control Variable</h6>
            <b-row>
                <b-col style="margin-left: 3px; margin-right: 3px;" cols="3" class="text-center variable-item" v-on:click="selectControlVariable(item)" :style="setActiveStyle(item.id)" v-for="item in control_variables" :key="item.id">{{item.name}}</b-col>
            </b-row>
            <div v-if="selected_variable_id != null">
                <hr>
                <h6>Select The Choice To Display This Element</h6>
                <b-row>
                    <b-col class="variable-item" v-on:click="setSelectedOption(item)" style="margin-left: 3px; margin-right: 3px;" :style="setActiveOption(item.value)" cols="3" v-for="(item, idx) in selectedVariableOptions" :key="idx">
                        {{item.text}}
                    </b-col>
                </b-row>
            </div>
        </b-form>
        <template #modal-footer>
            <div >
                <b-button v-on:click="$bvModal.hide('conditionalRendering')">Close</b-button>
                &nbsp;
                <b-button variant="success" v-on:click="saveElement()">Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import { builderFormComputed } from '@/state/helpers'
import _ from 'lodash'
export default {
    props:{
        current_group_index:{
            required: false,
            type: Number,
        }
    },
    data:() => ({
        hasMounted: false,
        groups:[],
        current_element:{},

        form_elements:[],
        control_variables: [],
        selected_variable_id: null,
        selected_variable: null,
        selected_option: null,
    }),
    computed:{
        ...builderFormComputed,

        selectedVariableOptions(){
            var id = this.selected_variable_id;
            if(id == null){
                return [];
            }
            else{
                var index = _.findIndex(this.control_variables, {id : id});
                if(index != -1){
                    return this.control_variables[index].option_values;
                }
                else{
                    return []
                }
            }
        }
    },
    methods:{
        saveElement(){
            if(this.selected_option == null && this.selected_variable_id != null){
                Swal.fire('Please select an option for the selected variable.');
            }
            else{
                this.$emit('savedElement', this.current_element);
            }
            
        },
        selectControlVariable(variable){
            if(variable.id == this.selected_variable_id){
                this.selected_variable = null;
                this.selected_variable_id = null;
                this.selected_option = null;
                this.current_element.options['conditional_text'] = false;
                this.current_element.options['conditional_value'] = false;
                this.current_element.options['conditional_id'] = false;
                this.current_element.options['conditional_variable'] = false;
            }
            else{
                this.selected_option = null;
                this.selected_variable = variable
                this.selected_variable_id = variable.id;
            }
        },
        setSelectedOption(item){
            if(this.selected_option == item.value){
                this.selected_option = null;
                this.current_element.options['conditional_value'] = '';
                this.current_element.options['conditional_id'] = '';
                this.current_element.options['conditional_variable'] = '';
                this.current_element.options['conditional_text'] = '';
            }
            else{
                this.selected_option = item.value;
                this.current_element.options['conditional_variable'] = item.text;
                this.current_element.options['conditional_value'] = item.value
                this.current_element.options['conditional_id'] = this.selected_variable_id;
                this.current_element.options['conditional_text'] = this.selected_variable.name;
            }
        },
        setActiveStyle(id){
            if(id == this.selected_variable_id){
                return 'border: 1px solid dodgerblue; background: rgba(30, 143, 255, 0.192);';
            }
            else{
                return '';
            }
        },
        setActiveOption(option){
            if(this.selected_option == option){
                return 'border: 1px solid dodgerblue; background: rgba(30, 143, 255, 0.192);';
            }
            else{
                return '';
            }
        },
        
    },
    mounted(){
        var editData = _.cloneDeep(this.editData);
        this.groups = editData.groups;
        this.current_element = editData.current_element;
        if(this.current_element.options['conditional_id'] != undefined || this.current_element.options['conditional_id'] != ''){
            this.selected_variable_id = this.current_element.options['conditional_id'];
            if(this.current_element.options['conditional_value'] != undefined || this.current_element.options['conditional_value'] != ''){
                this.selected_option = this.current_element.options['conditional_value'];
            }
        }

        let current_group = this.groups.find( group => {
            return group.id == this.current_element.form_group;
        })
        current_group.elements.forEach(element => {
            if(element.type == 'select' || element.type == 'radio'){
                this.control_variables.push(element)
            }
            else{
                this.form_elements.push(element);
            }
        })

        //this.groups.forEach(group => {
        //    console.log('Group: ', group)
        //    group.elements.forEach(element => {
        //        if(element.type == 'select' || element.type == 'radio'){
        //            this.control_variables.push(element)
        //        }
        //        else{
        //            this.form_elements.push(element);
        //        }
        //    })
        //})

        if(this.control_variables.length == 0){
            Swal.fire('','Please add a Radio Group or Dropdown Selection to enable conditional rendering', 'warning')
            .then('Close me');
        }
        
    },
}
</script>

<style>
.variable-item{
    border: 1px solid lightgray;
    border-radius: 4px;
    cursor: pointer;
}
.variable-item:hover{
    border: 1px solid grey;
    background: rgba(30, 143, 255, 0.192);
}
</style>